import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { SecondaryNavbar, Container } from '@mch-group/uikit-components'
import qs from 'query-string'

import { requestFilters, setCurrentShowTab } from '@stores/catalog'
import { filterEntityTypes } from '@constants/entityTypes'

const MenuShow = ({
  filters = [],
  query,
  requestFiltersStore,
  setCurrentShowTabStore,
  menuShows
}) => {
  const router = useRouter()
  const activeItem = menuShows?.findIndex(item => {
    let index
    if (item.key === router?.query?.showIds) {
      index = item.key === router?.query?.showIds
    }
    return index
  })
  const handleItemClick = useCallback(async (event) => {
    window.scrollTo(0, 0)
    const selectedItem = menuShows.filter((item) => item.label === event.label)
    let showIdsOfItem
    showIdsOfItem = selectedItem[0].key || undefined
    const entityShow = filters.find(entity => entity.entityType === filterEntityTypes.show)
    if (entityShow) {
      const show = entityShow.options.find(showValue => showValue.showIds === showIdsOfItem)
      if (show) {
        setCurrentShowTabStore(show.showIds)
      } else {
        // set to All Shows
        setCurrentShowTabStore('')
      }
    }
    if (showIdsOfItem) {
      await requestFiltersStore(query.slug, { showIds: showIdsOfItem })
    } else {
      await requestFiltersStore(query.slug, { showIds: undefined })
    }
    const params = {
      showIds: showIdsOfItem,
      locations: undefined,
      sectorIds: undefined,
      medium: undefined,
      startYear: undefined,
      endYear: undefined,
      favorite: undefined,
      sortBy: query.slug === '/artworks' ? 'random' : 'sortingName',
      atozLetter: query.slug === '/artworks' ? undefined : 'all'
    }
    router.push({ pathname: '/', query: params }, `${query.slug}?${qs.stringify(params)}`, { shallow: true })
  }, [menuShows, filters, requestFiltersStore, query.slug, router, setCurrentShowTabStore])

  return (
    <div>
      <Container className='px-5 px-lg-8 px-md-7'>
        <SecondaryNavbar
          data={menuShows}
          compact
          activeKey={activeItem > 0 ? activeItem : 0}
          linkRenderer={(data) => (
            <div
              onClick={() => {
                handleItemClick(data)
              }}
              tabIndex={0}
              role='button'
              onKeyDown={() => { handleItemClick(data) }}
            >
              {/* this <a> is unnecessary here but the MP team expects us to use
             <a> tag and have applied css on <a> tag for spacings of the tabs */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                {data?.label}
              </a>
            </div>
          )}
        />
      </Container>
    </div>
  )
}

MenuShow.displayName = 'MenuShow'

MenuShow.propTypes = {
  query: PropTypes.object,
  filters: PropTypes.array,
  requestFiltersStore: PropTypes.func,
  setCurrentShowTabStore: PropTypes.func,
  menuShows: PropTypes.array
}

export default connect(
  ({ page, catalog }) => ({
    currentParams: catalog.currentParams,
    defaultParams: catalog.defaultParams,
    currentShowTab: catalog.currentShowTab,
    filters: catalog.filters,
    query: page.query
  }),
  {
    requestFiltersStore: requestFilters,
    setCurrentShowTabStore: setCurrentShowTab
  }
)(MenuShow)
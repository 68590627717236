import { useCallback, useContext, useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import classNames from 'classnames'
import { getSiteLanguage } from '@utils'
import { Container } from '@mch-group/uikit-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import { PageContext } from '@providers/PageContext'
import { LoginContext } from '@providers/Login'
import { Img, Overlay } from './Image'
import VIPR from './VIPR'
import Menu from './Menu'
import NavigationOnlyTemplate from './NavigationOnlyTemplate'

const mID = 'rm-secondary-header'

const SecondaryHeader = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [bgImage, setBgImage] = useState(null)
  const bgImgClTransformations = 'ar_16:9,c_fill'
  const { user } = useContext(LoginContext)
  const gallery = user.profiles && user.profiles.find((element) => element.type === 'Gallery')
  const { page: { query } } = useContext(PageContext)

  const loadImage = useCallback(() => {
    const bgImgCl = uploadTransformationUrl(props.secom_background_image, bgImgClTransformations, {
      transformUrl: cloudinary.throughCloudfront
    })
    const image = new Image()
    image.src = bgImgCl
    image.onload = () => {
      setImageLoaded(true)
      setBgImage(null)
    }
  }, [props.secom_background_image])

  useEffect(() => {
    if (props.secom_background_image) {
      loadImage()
    }
    if (query?.slug === '/dashboard/overview') {
      window.scrollTo(0, 0)
    }
  }, [loadImage, query, props.secom_background_image])

  const isMobileWebview = query?.isMobileWebview

  const data = {
    displayVIPR: user.me && user.haveVipRepresentative && props.secom_complement === 'VIP-Representative',
    displayUsername:
      props.secom_user_name === 'true' && (props.secom_account === 'account-personal' || !props.secom_account),
    displayExhibitor: props.secom_user_name === 'true' && props.secom_account === 'account-exhibitor',
    displayMenu:
      'secom_secondary_menu_from' in props &&
      props.secom_secondary_menu_from !== '' &&
      typeof props.secom_secondary_menu_from !== 'undefined',
    displayMenuShows:
      'secom_menu_shows' in props &&
      props.secom_menu_shows &&
      props.secom_menu_shows.length > 0 &&
      typeof props.secom_menu_shows !== 'undefined',
    lqip: props.secom_background_image
      ? uploadTransformationUrl(props.secom_background_image, bgImgClTransformations, {
        transformUrl: cloudinary.throughCloudfront
      })
      : '',
    label: props.secom_label || null,
    title: props.secom_title1 || null,
    title2: props.secom_title2 || null,
    template: props.secom_template === 'horizontal-template' ? 'h' : 'v',
    favoriteLabel: props.secom_favorite_button_label || 'View Favorites',
    displayFavorite:
      (props.secom_complement === 'Events-Favorite' || props.secom_complement === 'Catalog-Favorite') &&
      props.secom_favorite_button_label,
    favoriteElement: props.secom_complement,
    bgImg: bgImage || (props.secom_background_image
      ? uploadTransformationUrl(props.secom_background_image, bgImgClTransformations, {
        transformUrl: cloudinary.throughCloudfront
      })
      : '')
  }

  const hasBgImage = !!data.bgImg

  if (data.displayUsername && user.me) {
    data.title = `${user.me.firstName} ${user.me.lastName}`
    data.title2 = null
  }

  if (data.displayExhibitor && gallery) {
    data.title = gallery.name
    data.title2 = null
  }

  const menuData = {
    from: props.secom_secondary_menu_from,
    highlight: props.secom_highlighted_url,
    menuShows: props.secom_menu_shows,
    displayMenuShows: data.displayMenuShows
  }

  return props?.secom_template === 'navigation-only' ? (
    <NavigationOnlyTemplate
      pageData={data}
      menuData={menuData}
      secomTemplate={props.secom_template}
    />
  ) : (
    <ThemeProvider
      theme={{
        _rmSH: {
          template: data.template,
          hasLabel: !!data.label,
          hasTitle: !!data.title,
          hasTitle2: !!data.title2,
          hasImg: hasBgImage,
          imageLoaded,
          displayVIPR: data.displayVIPR,
          displayMenu: data.displayMenu,
          displayFavorite: data.displayFavorite,
          displayMenuShows: data.displayMenuShows
        }
      }}
    >
      <div className={`secom-module ${hasBgImage ? '' : 'mt-lg-13 mt-md-11 mt-8'} ${classNames(mID, data.label ? 'has-label' : '', isMobileWebview ? 'no-margin-top' : '')}`}>
        <Img />
        <Overlay
          // @ts-ignore
          imageSrc={data.bgImg}
        >
          <Container>
            <TitleContainer className={`ps-5 px-sm-5 px-md-7 px-lg-8 ${hasBgImage ? 'py-11 py-md-14 pb-lg-16 pt-lg-17' : 'mb-lg-9 mb-md-8 mb-7'}`}>
              {
                (!user.profileDataLoading && !user.meIsLoading) &&
                <>
                  <div className='title-and-labels'>
                    {data.label && <p className={`mb-3 ${hasBgImage ? 'text-color-white' : ''}`}>{data.label}</p>}
                    <MainTitle>
                      <h1 className={`h2 ${getSiteLanguage() === 'zh_CN' ? 'header-title' : ''}  ${hasBgImage ? 'text-color-white' : ''}`}>{`${data.title ? data.title : ''} `}</h1>

                      {data.title2 &&
                        <>
                          <div className={`desktop-only h2 ${getSiteLanguage() === 'zh_CN' ? 'header-second-title' : ''} ${hasBgImage ? 'text-color-white' : 'text-color-grey'}`}>|</div>
                          <div className={`h3 text-paragraph-1 desktop-only ${getSiteLanguage() === 'zh_CN' ? 'header-second-title' : ''} ${hasBgImage ? 'text-color-white' : 'text-color-grey'}`}>{` ${data.title2}`}</div>
                        </>
                      }
                    </MainTitle>
                    {data.title2 &&
                      <div className={`h3 text-paragraph-1 mobile-tablet-only ${hasBgImage ? 'text-color-white' : 'text-color-grey'}`}>{data.title2}</div>
                    }
                  </div>
                  <div className='vip-rep'>
                    {(data.displayVIPR && !hasBgImage) && <VIPR />}
                  </div>
                </>
              }
            </TitleContainer>
          </Container>
        </Overlay>
        {data.displayMenu && !data.displayMenuShows && <Menu hasBgImage={hasBgImage} {...menuData} />}
        {data.displayMenuShows && <Menu {...menuData} hasBgImage={hasBgImage} />}
      </div>
    </ThemeProvider>
  )
}

const MainTitle = styled.div`
  display: flex;
  grid-gap: 12px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${global.uikitVars.breakpointSmMax}) {
    flex-direction: column;
  }

  .title-and-labels {
    width: 50%;

    .text-color-white {
      color: var(--bs-mch-white);
    }

    .text-color-grey {
      color: var(--bs-mch-gray-500);
    }

    @media (max-width: ${global.uikitVars.breakpointSmMax}) {
      width: auto;
    }
  }

  .vip-rep {
    width: 50%;

    @media (max-width: ${global.uikitVars.breakpointSmMax}) {
      width: auto;
    }
  }

  .desktop-only {
    @media (max-width: ${global.uikitVars.breakpointMdMax}) {
      display: none;
    }

    margin-top: -4px;
  }

  .header-title {
    margin-top: -4px;
  }

  .header-second-title {
    margin-top: 0 !important;
  }

  .mobile-tablet-only {
    @media (min-width: ${global.uikitVars.breakpointLg}) {
      display: none;
    }
  }
`

export default SecondaryHeader
import { FC } from 'react'
import Menu from './Menu'

interface PageData {
  displayMenu: boolean,
  displayMenuShows: boolean
}

interface NavigationProps {
  pageData: PageData,
  menuData: object,
  secomTemplate: string
}
const NavigationOnlyTemplate:FC<NavigationProps> = ({ pageData, menuData, secomTemplate }) => (
  <div className='secom-module mt-lg-13 mt-md-11 mt-9'>
    {/** @ts-ignore */}
    {pageData?.displayMenu && !pageData.displayMenuShows && <Menu template={secomTemplate} {...menuData} />}
    {/** @ts-ignore */}
    {pageData?.displayMenuShows && <Menu template={secomTemplate} {...menuData} />}
  </div>
)

export default NavigationOnlyTemplate

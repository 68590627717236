import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils/storage'
import { logger } from '@utils'
import { User as UserService } from '@services'
import { setHaveVipRepresentative } from '@stores/user'
import { LoginContext } from '@providers/Login'

import Text from '../../Text'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const VIPRComponent = () => {
  const { user } = useContext(LoginContext)
  const [isVipRepresentativeFetched, setIsVipRepresentativeFetched] = useState(false)
  const { labels: apiLabels = {} } = useLabelsFromAPI(['VIPRepresentative'], getSiteLanguage())
  // The object set in this state, is the object from BE, plus few more properties
  const [vipRObj, setVipRObj] =
    useState<ABTypes.Userprofiles.VipRepresentative &
    {
      title: string,
      displayEmail: string,
      displayFullname: string
    }>({
      fullName: '',
      profileImage: '',
      title: '',
      displayEmail: '',
      displayFullname: ''
    })

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user.me && !isVipRepresentativeFetched) {
          const vipRepresentativeResponse = await UserService.getVipRepresentative()
          setIsVipRepresentativeFetched(true)
          if (vipRepresentativeResponse.ok && vipRepresentativeResponse.data) {
            const title = apiLabels?.VIPRepresentative?.title || 'VIP Representative'
            const defaultEmail = apiLabels?.VIPRepresentative?.defaultEmail || 'vip@artbasel.com'
            const { fullName, profileImage = '', email } = vipRepresentativeResponse.data
            const displayEmail = email || defaultEmail
            const displayFullname = (fullName && email) ? fullName : ''
            setHaveVipRepresentative(!!fullName)
            await setVipRObj({
              fullName,
              profileImage,
              title,
              displayEmail,
              displayFullname
            })
          }
        }
      } catch (e) {
        logger(e)
      }
    }
    fetchData()
  }, [user, apiLabels, isVipRepresentativeFetched])


  const vipRImagePlaceHolder = () => {
    const { fullName } = vipRObj
    const firstName = fullName?.split(' ')[0]
    const lastName = fullName?.split(' ')[1]
    return (
      <ImagePlaceHolder>
        {
          (firstName && firstName.length ? firstName[0] : '') +
          (lastName && lastName.length ? lastName[0] : '')
        }
      </ImagePlaceHolder>
    )
  }

  const { displayFullname, fullName, title, displayEmail, profileImage } = vipRObj

  if (!user.me || !fullName) return null

  return (
    <VIPR className='mt-md-0 mt-8'>
      {profileImage !== '' ? <img src={profileImage} alt={title} /> : vipRImagePlaceHolder()}
      <Info>
        <p className='text-label-large mb-3'>{title}</p>
        {displayFullname &&
          <p className='text-medium mb-0'>{displayFullname}</p>
        }
        {displayEmail &&
          <Email className='text-medium mb-0'>
            <a href={`mailto:${displayEmail}`}>{displayEmail}</a>
          </Email>
        }
      </Info>
    </VIPR>
  )
}

const VIPR = styled.div`
  display: flex;
  flex-direction: row;
  align-self: ${({ theme }) =>
    // eslint-disable-next-line no-underscore-dangle
    (theme._rmSH.template === 'h' ? 'flex-end' : 'center')
  };
  position: relative;
  right: 0;
  top: 0;
  float: right;

  @media (max-width: ${global.uikitVars.breakpointSmMax}) {
    float: left;
  }

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  margin-left: 16px;
`

const Email = styled(Text)`
  word-break: break-all;
`

const ImagePlaceHolder = styled.h4`
  width: 72px;
  height: 72px;
  padding: 26px 18px 18px 19px;
  text-transform: ${tokens.textCase.uppercase.value};
  text-align: center;
  border-radius: 50%;
  object-fit: cover;
  color: var(--bs-mch-gray-400);
  background-color: var(--bs-mch-gray-200);
`

VIPRComponent.displayName = 'VIPR'

export default VIPRComponent

import { createSelector } from 'reselect'

import { cmsSkeletonModules } from '../constants/cms'
import { getTranslationByName } from '../utils/getTranslationByName'

const getSecondaryNavDefinitionFromFirstModuleInPage = (componentCmsData, moduleType, labelPropName, hrefPropName) => {
  const pageMenuModule = componentCmsData ? componentCmsData.find(item => item.moduleName === moduleType) : null

  if (pageMenuModule && 'componentProps' in pageMenuModule) {
    return {
      label: pageMenuModule.componentProps[labelPropName],
      href: pageMenuModule.componentProps[hrefPropName]
    }
  }
  return null
}

const getSecondaryNavDefinitionFromProps = (props) => {
  if ('from' in props && props.from) {
    return {
      label: props.from,
      href: props.hightlight
    }
  }
  return null
}

const getPageMenuData = (state, props) => {
  const { componentCmsData, query } = state.page

  const secondaryNav = getSecondaryNavDefinitionFromProps(props)
    // Get data from the "first" Secondary Header module
    || getSecondaryNavDefinitionFromFirstModuleInPage(
      componentCmsData, cmsSkeletonModules.secondaryHeader,
      'secom_secondary_menu_from', 'secom_highlighted_url'
    )
    // Get data from the "first" RmPageMenu module (Deprecated. To make it Backward compatible
    || getSecondaryNavDefinitionFromFirstModuleInPage(
      componentCmsData, cmsSkeletonModules.pageMenu,
      'pagemenu_grp1_subnavigation', 'pagemenu_grp1_highlight_menu_url'
    )
    // Default if no menu in the page
    || { label: null, href: null }

  // Get data from redux store inside navigation.secondary.<name of the menu>
  const data = { labels: {} }
  const secondaryNavigationItems = state.navigation.secondary
  if (secondaryNav.label) {
    const mySecondaryNavigationItems = secondaryNavigationItems[secondaryNav.label]
    if (mySecondaryNavigationItems) {
      mySecondaryNavigationItems.forEach((item, index) => {
        data.labels[`snd_mnu_${index}`] = {
          link: item.url,
          label: item.name
        }
      })
    }
  }

  return {
    ...secondaryNav,
    data,
    query,
    highlight: props.highlight
  }
}

export const getPageMenuDataSelector = createSelector(
  [getPageMenuData],
  (pageMenuData) => {
    const { data, query, highlight, ...secondaryNav } = pageMenuData
    const menuItems =
      data && 'labels' in data
        ? Object.keys(data.labels).map((key) => {
          const { link, label } = data.labels[key]

          return { key, link, label }
        })
        : []
    // activeItem comes from "Highlighted URL" field in SECOM
    // if its empty, we highlight the item that matches with the query.slug
    return {
      menuItems,
      activeItem: menuItems.filter(item => item.link === highlight || query.slug)[0] || null,
      ...secondaryNav
    }
  }
)

// the secondary menus from galleries doesn't take data from global modules, it is taken from
// galleries.secondaryMenus
const getPageMenuDataShow = (state) => {
  const { secondaryMenus, currentParams } = state.catalog

  const data = secondaryMenus

  return {
    data,
    currentParams
  }
}

// get the list of items for secondary menu in galleries page
export const getPageMenuShowDataSelector = createSelector(
  [getPageMenuDataShow],
  (pageMenuData) => {
    const { data, currentParams } = pageMenuData

    return {
      menuItems: data,
      // check active item based on showIds parameter
      activeItem: data.filter((item) => {
        if (!currentParams.showIds) {
          return !item.showIds
        }
        const firstShow = currentParams.showIds.split(',').length > 0 ? currentParams.showIds.split(',')[0] : ''
        const showOption = currentParams.showIds ?
          item.showIds.split(',').includes(firstShow) : firstShow === ''
        return showOption
      })[0] || data[0]
    }
  }
)

const getHeaderMenuList = (state) => {
  const primaryMenuItems = state.navigation.primary
  const { labels } = state.shared

  const getTranslatedLabel = name => getTranslationByName(labels, state.page.siteLanguage, 'PageHeader', name)

  const secondaryMenuItems = [
    {
      key: 'pnv_mnu_login',
      href: getTranslatedLabel('loginHref'),
      label: getTranslatedLabel('loginLabel')
    },
    {
      key: 'pnv_mnu_logout',
      href: getTranslatedLabel('logoutHref'),
      label: getTranslatedLabel('logoutLabel')
    }
  ]

  return {
    primaryMenuItems,
    secondaryMenuItems
  }
}

export const getHeaderMenuListSelector = createSelector(
  [getHeaderMenuList],
  headerMenuList => ({ ...headerMenuList })
)

export default {
  getPageMenuDataSelector,
  getHeaderMenuListSelector
}

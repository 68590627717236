/* eslint-disable no-shadow */
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { CustomLinkTag } from '@components'
import { SecondaryNavbar, Container } from '@mch-group/uikit-components'
import { getPageMenuDataSelector } from '../../../stores/_selectors'
import { setSecondaryNavigation } from '../../../stores/navigation'
import MenuShow from './MenuShow'

const MenuContainer = ({
  data,
  menuShows,
  displayMenuShows,
  setSecondaryNavigation,
  hasBgImage
}) => {
  let isSecondaryNavVisible = !displayMenuShows && data?.menuItems?.length > 0
  let isShowSecondaryNavVisible = displayMenuShows && menuShows?.length > 0

  useEffect(() => {
    setSecondaryNavigation(data.label)
  }, [data.label, setSecondaryNavigation])

  if (
    (!data || !('menuItems' in data) || ('menuItems' in data && data.menuItems.length === 0)) &&
    (!menuShows || (menuShows && menuShows.length === 0))
  ) {
    return null
  }

  return isSecondaryNavVisible || isShowSecondaryNavVisible ? (
    <div>
      <Container className={`px-lg-8 px-md-7 px-sm-5 ${hasBgImage ? '' : 'mt-7 mt-md-8'}`}>
        {isSecondaryNavVisible &&
          <SecondaryNavbar
            data={data?.menuItems}
            linkRenderer={(data) => (
              <CustomLinkTag url={data?.link}>
                {data?.label}
              </CustomLinkTag>
            )}
            activeKey={data?.menuItems?.findIndex(item => item.link === window?.location?.pathname)}
            compact
          />
        }
      </Container>
      {isShowSecondaryNavVisible &&
        <MenuShow menuShows={menuShows} />
      }
    </div>
  ) : null
}

MenuContainer.propTypes = {
  data: PropTypes.object,
  menuShows: PropTypes.array,
  displayMenuShows: PropTypes.bool,
  setSecondaryNavigation: PropTypes.func,
  hasBgImage: PropTypes.bool
}

export default connect(
  () => (state, props) => ({
    data: getPageMenuDataSelector(state, props)
  }),
  { setSecondaryNavigation }
)(MenuContainer)
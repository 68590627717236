import styled from 'styled-components'

export const Img = styled.div`
  position: absolute;
`

// NOTE: May be used in future
// ${({ theme, imageSrc }) => (theme._rmSH.hasImg ? `background-image: linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)),url(${imageSrc});` : null)}
export const Overlay = styled.div`
  ${({ theme, imageSrc }) =>
    // eslint-disable-next-line no-underscore-dangle
    (theme._rmSH.hasImg ? `background-image: url(${imageSrc});` : null)
  };
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
`
